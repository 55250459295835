import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { css, keyframes } from 'styled-components';
import Cookies from 'js-cookie';
import ImageContext from '../contexts/ImageContext';
import SavedImagesContext from '../contexts/SavedImagesContext';
import SelectSavedIntentContext from '../contexts/SelectSavedIntentContext';
import '../css/fonts.css';


const goAway = keyframes`
    from {
      opacity: 1;
      transform: scale(1);
    }
    to {
      opacity: .2;
      transform: scale(.95);
    }
`

const pulse = keyframes`
    0% { opacity: 0.5; }
    50% { opacity: 1; }
    100% { opacity: 0.5; }
`

const DaddyDiv = styled.div`
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    animation: ${({ animate }) => (animate ? css`${goAway} 0.25s forwards` : 'none')};
    opacity: 1;
`

const Title = styled.h1`
  font-family: 'Poppins', sans-serif;
  font-size: 42px;
  margin-bottom: 0px;
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
  @media (max-width: 600px) {
    font-size: 27px;
  }
  @media (max-width: 470px) {
    font-size: 28px;
  }
`;

const Text1 = styled.div`
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
  color: red;
  @media (max-width: 600px) {
    font-size: 14px;
    margin-bottom: 20px;
    margin-top: 10px;
  }
`

const Text2 = styled.div`
  font-family: 'Poppins', sans-serif;
  font-size: 22px;
  margin-bottom: 10px;
  text-align: center;
  @media (max-width: 600px) {
    font-size: 14px;
  }
`

const ImagesContainer = styled.div`
  width: 120%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 18vw));
  grid-gap: 10px;
  overflow: hidden;
  margin-bottom: 3rem;
  justify-content: center;
  @media (max-width: 600px) {
    grid-template-columns: repeat(auto-fill, minmax(150px, 18vw));
  }
`

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  box-sixing: border-box;
  border-radius: 20px;
  &:hover {
    transition: all 0.05s ease-in-out;
  }
  &:active {
    transform: scale(.95);
    transition: all 0.05s ease-in-out;
  }
  @media (max-width: 600px) {
    &:hover {
      transform: scale(1);
      transition: all 0.05s ease-in-out;
    }
    &:active {
      transform: scale(1);
      transition: all 0.05s ease-in-out;
    }
  }
`

const ImageText = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  color: white;
  font-size: 1.5em;
  text-align: center;
  visibility: hidden;
  ${ImageContainer}:hover & {
    visibility: visible;
    transition: all 0.4s ease-in-out;
  }
  font-family: 'Poppins', sans-serif;
  @media (max-width: 600px) {
    font-size: 14px;
  }
`

const Image = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  filter: ${props => props.selected ? 'blur(15px)' : ''}
`

const CheckmarkContainer = styled.div`
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(+50%, -50%);
    display: ${props => props.selected ? 'block' : 'none'};
`

const GrayButton = styled.button`
    margin-bottom: 20px;
    background-color: #f5222d;
    color: white;
    width: 30%;
    min-width: calc(264px + 1.6rem);
    font-family: 'Poppins', sans-serif;
    padding: 0.5rem 1rem;
    border: none;
    height: 50px;
    border-radius: 30px;
    width: 10rem;
    min-width: 132px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Dot = styled.div`
    animation: ${pulse} 1s infinite;
    width: 10px;
    height: 10px;
    margin: 0 5px;
    background-color: white;
    border-radius: 50%;
`;

const ConfirmChoicesButton = styled.button`
    margin-bottom: 20px;
    background-color: ${props => props.valid ? '#f5222d' : '#949494'};
    color: white;
    width: 30%;
    font-size: 17px;
    min-width: calc(264px + 1.6rem);
    font-family: 'Poppins', sans-serif;
    padding: 0.5rem 1rem;
    border: none;
    height: 50px;
    border-radius: 30px;
    font-size: 15px;
    width: 10rem;
    min-width: 132px;
    &:hover {
        transform: scale(1.05);
        transition: all 0.05s ease-in-out;
        background-color: ${props => props.valid ? '#d5020d' : '#545454'};
    }
    &:active {
        transform: scale(.95);
        transition: all 0.05s ease-in-out;
    }
`;

const SelectSavedImages = () => {
    const { savedImages, setSavedImages } = useContext(SavedImagesContext);
    const { imageUrl, setImageUrl } = useContext(ImageContext);
    const { selectSavedIntent, setSelectSavedIntent } = useContext(SelectSavedIntentContext);
    const [imagesToRemove, setImagesToRemove] = useState([]);
    const [transition, setTransition] = useState(false);
    const [animate, setAnimate] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'Doodlebug | Saved Images';
    }, []);

    useEffect(() => {
        window.scrollTo(0,0);
    }, []);

    function selectOldImage(url) {
        if (!imagesToRemove.includes(url)) {
            const newImagesToRemove = imagesToRemove.slice();
            newImagesToRemove.push(url);
            setImagesToRemove(newImagesToRemove);
        } else {
            const newImagesToRemove = imagesToRemove.filter(value => value !== url);
            setImagesToRemove(newImagesToRemove);
        }
    }

    function handleRemove() {
        if (imagesToRemove.length < 1) {
            if ( selectSavedIntent === 'cleanup' ) {
                setAnimate(true);
                const timer = setTimeout(() => {
                navigate('/favorites');
                setSelectSavedIntent('');
            }, 500);
            return () => clearTimeout(timer);
            } else {
                return;
            }
        }
        const newSavedImages = savedImages.filter(url => !imagesToRemove.includes(url));
        setSavedImages(newSavedImages);
        const cookieDomain = process.env.REACT_APP_ENVIRONMENT === 'production' ? '.doodlebug.me' : null;
        Cookies.set('savedImages', JSON.stringify(newSavedImages), { domain: cookieDomain });
        if (selectSavedIntent === 'use') {
            setAnimate(true);
            setSelectSavedIntent('');
            Cookies.set('lastPage', 'customize', { expires: 7 , domain: cookieDomain });
            const timer = setTimeout(() => {
                navigate('/customize');
            }, 500);
            return () => clearTimeout(timer);
        } else {
            setTransition(true);
            Cookies.set('lastPage', 'savedImages', { expires: 7 , domain: cookieDomain });
            const timer = setTimeout(() => {
                navigate('/favorites');
                setSelectSavedIntent('');
            }, 500);
            return () => clearTimeout(timer);
        }
    }

    return (
        <>
            {transition && selectSavedIntent !== 'cleanup' ? (
                <DaddyDiv animate={animate}>
                    <Text1>You've reached the maximum number of saved images. To proceed, select one or more saved images to remove and confirm your choice below.</Text1>
                    <GrayButton><Dot></Dot><Dot></Dot><Dot></Dot></GrayButton>
                    <ImagesContainer>
                        {savedImages.map((url, index) => (
                            <ImageContainer key={url}>
                                <Image src={url}></Image>
                                <ImageText>Use this</ImageText>
                            </ImageContainer>
                        ))}
                    </ImagesContainer>
                </DaddyDiv>
            ) : !transition && selectSavedIntent !== 'cleanup' ? (
                <DaddyDiv animate={animate}>
                    <Text1>You've reached the maximum number of saved images. To proceed, select one or more saved images to remove and confirm your choice below.</Text1>
                    <ConfirmChoicesButton valid={imagesToRemove.length > 0} onClick={() => handleRemove()}>Remove ({imagesToRemove.length})</ConfirmChoicesButton>
                    <ImagesContainer>
                        {savedImages.map((url, index) => (
                            <ImageContainer key={url} selected={imagesToRemove.includes(url)}>
                                <Image src={url} onClick={() => selectOldImage(url)} selected={imagesToRemove.includes(url)}></Image>
                                <CheckmarkContainer onClick={() => selectOldImage(url)} selected={imagesToRemove.includes(url)}>
                                <svg fill="#000000" width="42px" height="42px" viewBox="0 0 24 24" id="check-circle" data-name="Flat Line" xmlns="http://www.w3.org/2000/svg" class="icon flat-line">
                                    <g id="SVGRepo_bgCarrier" stroke-width="0"/>
                                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
                                    <g id="SVGRepo_iconCarrier">
                                        <circle id="secondary" cx="12" cy="12" r="9" style={{"fill": "#f5222d", "stroke-width": "2"}}/>
                                        <polyline id="primary" points="8 12 11 15 16 10" style={{"fill": "none", "stroke": "#ffffff", "strokeLinecap": "round", "strokeLinejoin": "round", "strokeWidth": "2"}}/>
                                        <circle id="primary-2" data-name="primary" cx="12" cy="12" r="9" style={{"fill": "none", "stroke": "#ffffff", "strokeLinecap": "round", "strokeLinejoin": "round", "strokeWidth": "2"}}/>
                                    </g>
                                </svg>
                                </CheckmarkContainer>
                            </ImageContainer>
                        ))}
                    </ImagesContainer>

                </DaddyDiv>
            ) : transition && selectSavedIntent === 'cleanup' ? (
                <DaddyDiv animate={animate}>
                    <Text1>Select images to remove.</Text1>
                    <GrayButton><Dot></Dot><Dot></Dot><Dot></Dot></GrayButton>
                    <ImagesContainer>
                        {savedImages.map((url, index) => (
                            <ImageContainer key={url}>
                              <Image src={url}></Image>
                              <ImageText>Use this</ImageText>
                            </ImageContainer>
                        ))}
                    </ImagesContainer>
                </DaddyDiv>
            ) : (
                <DaddyDiv animate={animate}>
                <Text1>Select images to remove.</Text1>
                <ConfirmChoicesButton valid={true} onClick={() => handleRemove()}>{imagesToRemove.length > 0 ? `Remove (${imagesToRemove.length})` : 'Nevermind'}</ConfirmChoicesButton>
                <ImagesContainer>
                    {savedImages.map((url, index) => (
                        <ImageContainer key={url} selected={imagesToRemove.includes(url)}>
                            <Image src={url} onClick={() => selectOldImage(url)} selected={imagesToRemove.includes(url)}></Image>
                            <CheckmarkContainer onClick={() => selectOldImage(url)} selected={imagesToRemove.includes(url)}>
                            <svg fill="#000000" width="42px" height="42px" viewBox="0 0 24 24" id="check-circle" data-name="Flat Line" xmlns="http://www.w3.org/2000/svg" class="icon flat-line">
                                <g id="SVGRepo_bgCarrier" stroke-width="0"/>
                                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
                                <g id="SVGRepo_iconCarrier">
                                    <circle id="secondary" cx="12" cy="12" r="9" style={{"fill": "#f5222d", "stroke-width": "2"}}/>
                                    <polyline id="primary" points="8 12 11 15 16 10" style={{"fill": "none", "stroke": "#ffffff", "strokeLinecap": "round", "strokeLinejoin": "round", "strokeWidth": "2"}}/>
                                    <circle id="primary-2" data-name="primary" cx="12" cy="12" r="9" style={{"fill": "none", "stroke": "#ffffff", "strokeLinecap": "round", "strokeLinejoin": "round", "strokeWidth": "2"}}/>
                                </g>
                            </svg>
                            </CheckmarkContainer>
                        </ImageContainer>
                    ))}
                </ImagesContainer>

            </DaddyDiv>
            )}

        </>
    )
}

export default SelectSavedImages;