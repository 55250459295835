import React, { useEffect, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { Link } from 'react-router-dom';

const fadeIn = keyframes`
    from { opacity: 0; }
    to { opacity: 1; }
`;

const goAway = keyframes`
    from {
      opacity: 1;
      transform: scale(1);
    }
    to {
      opacity: .2;
      transform: scale(.95);
    }
`;

const Background = styled.div`
    width: 100%;
    background: #fff9ef;
`;

const BigContainer = styled.div`
    padding-top: 2.5rem;
    width: 62%;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    animation: ${({ animate }) => (animate ? css`${goAway} 0.25s forwards` : 'none')};
    @media (max-width: 600px) {
        width: 85%;
    }
    padding-bottom: 10rem;
`

const TitleText = styled.h1`
    font-family: 'NewKansas';
    font-size: 50px;
    margin-bottom: 20px;
    box-sizing: border-box;
    text-align: center;
    animation: ${fadeIn} 0.5s forwards;
    animation-delay: 0.2s;
    opacity: 0;
    @media (max-width: 600px) {
        font-size: 30px;
    }
`;

const Message = styled.div`
    font-family: 'Poppins', sans-serif;
    width: 100%;
    font-size: 20px;
    margin-bottom: 1rem;
    animation: ${fadeIn} 0.5s forwards;
    animation-delay: 0.7s;
    opacity: 0;
    @media (max-width: 600px) {
        font-size: 14px;
    }
`

const MailLink = styled.a`
    font-family: 'Poppins', sans-serif;
    width: 100%;
    font-size: 20px;
    margin-bottom: 1rem;
    animation: ${fadeIn} 0.5s forwards;
    animation-delay: 0.6s;
    opacity: 0;
    text-decoration: none;
    @media (max-width: 600px) {
        font-size: 14px;
    }
`

const Button = styled.button`
  width: 260px;
  border: none;
  height: 64px;
  padding: 10px 20px;
  color: #fff9ef;
  background: #fe814d;
  border-radius: 18px;
  font-weight: bold;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 3rem;
  transform: scale(1);
  margin-left: auto;
  margin-right: auto;
  &::after {
    content: '';
    position: absolute;
    border-radius: 68% / 5%;
    background-color: #fe814d;
    top: -1px;
    bottom: -1px;
    right: 13px;
    left: 13px;
    z-index: -1;
  }
  &:hover {
      transform: scale(1.03);
      transition: all 0.05s ease-in-out;
  }
  &:active {
      transform: scale(.99);
      transition: all 0.05s ease-in-out;
  }
  @media (max-width: 600px) {
    font-size: 14px;
    max-width: 250px;
  }
`;

const ButtonText = styled.div`
  font-family: 'NewKansas';
  text-align: center;
  font-size: 24px;
  @media (max-width: 600px) {
    font-size: 20px;
    margin-bottom: 0rem;
  }
`;

const LinkToHome = styled(Link)`
    text-decoration: none;
    width: 50%;
    max-width: 800px;
`


const Terms = () => {
    const [animate, setAnimate] = useState(false);

    // scroll to the top lol
    useEffect(() => {
        window.scrollTo(0,0);
    }, []);

    useEffect(() => {
        document.title = 'Doodlebug | Terms'
      }, []);

    const handleGoHome = () => {
        setAnimate(true);
        const timer = setTimeout(() => {}, 500);
        return () => clearTimeout(timer);
    }

    return (
        <>
            <Background>
                <BigContainer animate = {animate}>
                    <TitleText>Terms of Service</TitleText>
                    <Message>If you're using Doodlebug like a normal person, you needn't worry about these. Regardless, by using Doodlebug's servers, you agree to abide by the Doodlebug commandments:</Message>
                    <Message>1. Thou shalt not use Doodlebug's image generation service, or any of its outputs, for your own commercial use. Thou shalt not use Doodlebug's image generation service, or any of its outputs, for the commercial use of another party.</Message>
                    <Message>2. Thou shalt not use Doodlebug's image generation service, or any of its outputs, to train or fine-tune an AI model (including, but not limited to, any versions of Flux or Stable Diffusion), or otherwise use Doodlebug's image generation service, or any of its outputs, as inputs for an AI model.</Message>
                    <Message>3. Thou shalt not automate interaction with Doodlebug's image generation service.</Message>
                    <Message>4. Thou shalt not attempt to bypass rate limits on Doodlebug's image generation service.</Message>
                    <Message>5. Thou shalt not attempt to generate illegal or explicit content using Doodlebug's image generation service.</Message>
                    <Message>Doodlebug reserves the right to exclude anyone who infringes one or more of these rules from interacting with its services. Doodlebug may pursue legal action against individuals who infringe one or more of these rules. Contact <MailLink href="mailto:support@doodlebug.me">support@doodlebug.me</MailLink> with any questions about the Doodlebug commandments.</Message>
                    <LinkToHome to="/" onClick={handleGoHome}>
                        <Button><ButtonText>Return Home</ButtonText></Button>
                    </LinkToHome>
                </BigContainer>
            </Background>
        </>
    )
}

export default Terms;
