import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { css, keyframes } from 'styled-components';
import Slider from 'react-slick';
import Cookies from 'js-cookie';
import '../css/fonts.css';
import { ArrowBendRightDown } from '@phosphor-icons/react';
import hero_1200 from '../img/landing_images/hero_case_1200.webp';
import hero_full from '../img/landing_images/hero_case_full.webp';
import hero_with_backdrop from '../img/landing_images/hero_case_with_backdrop.png';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(5px); }
  to { opacity: 1; transform: translateY(0); }
`;

const goAway = keyframes`
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: .2;
    transform: scale(.95);
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  background: #fff9ef;
`;

const HeroBlock = styled.div`
  width: 85%;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  @media (max-width: 800px) {
    margin-top: 2rem;
  }
  padding-bottom: 5rem;
`

const Title = styled.h1`
  font-family: 'NewKansas';
  color: #222;
  font-size: 46px;
  margin-bottom: 4rem;
  box-sizing: border-box;
  text-align: left;
  animation: ${fadeIn} 0.5s forwards;
  animation-delay: 0.2s;
  opacity: 0;
  @media (max-width: 800px) {
    font-size: 32px;
    margin-bottom: 2rem;
  }
  @media (max-width: 470px) {
    font-size: 30px;
    margin-bottom: 2rem;
  }
`;

const Text1 = styled.div`
  font-family: 'NewKansas';
  text-align: center;
  font-size: 24px;
  @media (max-width: 600px) {
    font-size: 20px;
    margin-bottom: 0rem;
  }
`;

const Text2 = styled.div`
  font-family: 'Poppins', sans-serif;
  text-align: center;
  padding-left: 10px;
  font-size: 17px;
  @media (max-width: 600px) {
    font-size: 14px;
    margin-bottom: 0rem;
  }
`

const ContainerForAllHeroElements = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-top: 2rem;
  gap: 3rem;
  @media (max-width: 800px) {
    flex-direction: column;
    margin-top: 0;
    gap: 0;
  }
`

const ContainerForTitleAndButtons = styled.div`
  @media (min-width: 801px) {
    flex: 4;
  }
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  @media (max-width: 800px) {
    margin-top: 0;
  }
`

const HeroImageContainer = styled.div`
  @media (min-width: 801px) {
    flex: 6;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  max-height: 800px;
  position: relative;
  @media (max-width: 900px) {
    width: 100%;
    max-width: 800px;
    max-height: 800px;
    min-width: 0;
  }
  @media (max-width: 800px) {
    width: 100%;
    max-width: 800px;
    max-height: 800px;
    height: 100vw;
    min-width: 0;
    margin-top: 0;
  }
  & > img {
    object-fit: contain;
    height: 100%;
    width: 100%;
    z-index: 2;
  }
`

const SVGContainer = styled.div`
  z-index: 3;
  position: absolute;
  filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.4));
  left: 50%;
  top: 32%;
`

const ImageContainer = styled.div`
  border-radius: 20px;
  overflow: hidden;
`

const CarouselImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  ${ImageContainer}:hover & {
    transition: all 0.05s ease-in-out;
  }
`

const ButtonContainerForAnimation = styled.div`
  opacity: 0;
  animation: ${fadeIn} 0.5s forwards;
  animation-delay: 0.7s;
`

const CreateButton = styled.button`
  width: 260px;
  border: none;
  height: 64px;
  padding: 10px 20px;
  color: #fff9ef;
  background: #fe814d;
  border-radius: 18px;
  font-weight: bold;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
  transform: scale(1);
  &::after {
    content: '';
    position: absolute;
    border-radius: 68% / 5%;
    background-color: #fe814d;
    top: -1px;
    bottom: -1px;
    right: 13px;
    left: 13px;
    z-index: -1;
  }
  &:hover {
      transform: scale(1.03);
      transition: all 0.05s ease-in-out;
  }
  &:active {
      transform: scale(.99);
      transition: all 0.05s ease-in-out;
  }
  @media (max-width: 600px) {
    font-size: 14px;
    max-width: 250px;
  }
`;

const TrendingImagesButton = styled.button`
width: 180px;
border: none;
height: 52px;
padding: 10px 20px;
color: #fff9ef;
background: #222222;
border-radius: 18px;
font-weight: bold;
box-sizing: border-box;
display: flex;
justify-content: center;
align-items: center;
margin-bottom: 2rem;
transform: scale(1);
&::after {
  content: '';
  position: absolute;
  border-radius: 78% / 5%;
  background-color: #222222;
  top: -1px;
  bottom: -1px;
  right: 18px;
  left: 18px;
  z-index: -1;
}
&:hover {
    transform: scale(1.03);
    transition: all 0.05s ease-in-out;
}
&:active {
    transform: scale(.99);
    transition: all 0.05s ease-in-out;
}
@media (max-width: 600px) {
  font-size: 14px;
  max-width: 250px;
}
`;

const IconContainer1 = styled.div`
  display: flex;
  align-items: center;
  padding-right: 10px;
`

const IconContainer2 = styled.div`
  display: flex;
  align-items: center;
  padding-right: 10px;
  font-size: 20px;
`

const StyledSlider = styled(Slider)`
  .slick-slide > div {
    margin: 0 5px;
  }
  margin-bottom: 50px;
  opacity: 0;
  animation: ${fadeIn} 0.5s forwards;
  animation-delay: 0.7s;
  overflow: visible;
`;

const ArrowButton = styled.div`
  width: 30px;
  height: 30px;
  position: absolute;
  color: black;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 1;
  & > svg {
    width: 100%;
    height: auto;
  }
  &::before {
    display: none;
  }
  &:hover {
    color: black;
  }
`;


const Landing = () => {
  const [animate, setAnimate] = useState(false);
  const popularPrintsRef = useRef(null);
  const navigate = useNavigate();
  const [clientWidth, setClientWidth] = useState(window.innerWidth);
  const [heroImagesAreReady, setHeroImagesAreReady] = useState(false);
  const heroImagesAreReadyRef = useRef(heroImagesAreReady);
  const [heroImage, setHeroImage] = useState(hero_1200);

  // a function to load a single image into an image object
  const loadImage = async (src, useUniqueSrc) => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        const uniqueSrc = `${src}?t=${new Date().getTime()}`;
        if (useUniqueSrc) {
            img.src = uniqueSrc;
        } else {
            img.src = src;
        }
        img.onload = () => resolve(img);
        img.onerror = (error) => {
            reject(error);
        };
    });
  };

  const loadHeroImage = async () => {
    const loadedImage = await loadImage(heroImage);
    setHeroImage(loadedImage);
  }

  // loading our hero image so we don't have to continually make requests for the resource
  useEffect(() => {
    loadHeroImage();
  }, []);

  useEffect(() => {
    const adjustHeroImageForResize = () => {
      // const newHeroImage =
      //   document.documentElement.clientWidth <= 800 ? hero_800 :
      //   document.documentElement.clientWidth <= 1200 ? hero_1200 :
      //   document.documentElement.clientWidth <= 1600 ? hero_1600 :
      //   document.documentElement.clientWidth > 1600 ? hero_1920 :
      //   hero_800;
// 
      //   setHeroImage(newHeroImage);
    }

    const handleAllResizeEvents = () => {
      adjustHeroImageForResize();
      setClientWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleAllResizeEvents);

    return () => {
        window.removeEventListener('resize', handleAllResizeEvents);
    }
  }, []);

  useEffect(() => {
    document.title = 'Doodlebug | Cute friends to bring with you'
  }, []);

  useEffect(() => {
    window.scrollTo(0,0);
  }, []);

  async function handleImageClick(url) {
    setAnimate(true);
    const cookieDomain = process.env.REACT_APP_ENVIRONMENT === 'production' ? '.doodlebug.me' : null;
    Cookies.set('lastPage', 'ordering', { expires: 7 , domain: cookieDomain });
    const imageKey = url.split('/').pop();
    const timer = setTimeout(() => {
      navigate(`/case?case=${imageKey}`)
    }, 500);

    return () => clearTimeout(timer);
  }

  function navigateToCustomize() {
    const cookieDomain = process.env.REACT_APP_ENVIRONMENT === 'production' ? '.doodlebug.me' : null;
    Cookies.set('lastPage', 'new-doodlebug', { expires: 7 , domain: cookieDomain });
    navigate('/new-doodlebug');
  };

  return (
    <>
      <Wrapper>
        <HeroBlock>
          <ContainerForAllHeroElements>
            <ContainerForTitleAndButtons>
              <Title>Cute friends to take with you!</Title>
              <ButtonContainerForAnimation><CreateButton onClick={() => navigateToCustomize()}><Text1>Make my own </Text1></CreateButton></ButtonContainerForAnimation>
              <ButtonContainerForAnimation><TrendingImagesButton onClick={() => popularPrintsRef.current.scrollIntoView({ behavior: 'smooth' })}><Text2>Browse </Text2><IconContainer2><ArrowBendRightDown size={32} /></IconContainer2></TrendingImagesButton></ButtonContainerForAnimation>
            </ContainerForTitleAndButtons>
            <HeroImageContainer>
              <img src={hero_with_backdrop}/>
              
            </HeroImageContainer>
          </ContainerForAllHeroElements>
        </HeroBlock>
      </Wrapper>
    </>
  );
}

export default Landing;
