import React, { useContext, useEffect } from 'react';
import UserInfoContext from "../contexts/UserInfoContext";
import SavedDoodlebugsContext from "../contexts/SavedDoodlebugsContext";
import ItemsContext from '../contexts/ItemsContext';

const GetUserState = () => {
  const { userInfo, setUserInfo } = useContext(UserInfoContext);
  const { savedDoodlebugs, setSavedDoodlebugs } = useContext(SavedDoodlebugsContext);
  const { items, setItems } = useContext(ItemsContext);

  useEffect(() => {
    const fetchInitialState = async () => {
        const userInfoUrl = process.env.REACT_APP_ENVIRONMENT === 'production' ? 'https://api.doodlebug.me/api/user-info' : 'http://localhost:3001/api/user-info';
        try {
          const response = await fetch(userInfoUrl, {
            credentials: 'include'
          });
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const userData = await response.json();
          const userDataWithStatus = {
            ...userData,
            status: "authenticated"
          };
          setUserInfo(userDataWithStatus);
          setSavedDoodlebugs(userData.doodlebugs);
        } catch (error) {
          console.error('Failed to fetch initial state:', error);
          setSavedDoodlebugs([]);
          setUserInfo({status: "unauthenticated"});
        }
    };
    
    const fetchCart = async () => {
      const cartUrl = process.env.REACT_APP_ENVIRONMENT === 'production' ? 'https://api.doodlebug.me/get-cart' : 'http://localhost:3001/get-cart';
      try {
        const response = await fetch(cartUrl, {
          credentials: 'include'
        });
        if (!response.ok) {
          throw new Error('fetch cart is a no-go');
        }
        const serverCartItems = await response.json();
        setItems(serverCartItems.items);
      } catch (error) {
        console.error('failed to fetch cart: ', error);
        setItems([]);
      }
    }

    fetchInitialState();
    fetchCart();
  }, [setUserInfo]);

  return null;
};

export default GetUserState;