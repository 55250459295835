import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import ContentLoader from 'react-content-loader';
import useImage from 'use-image';
import Select from 'react-select';
import { Image, Layer, Rect, Stage, Transformer } from 'react-konva';
import styled, { css, keyframes } from 'styled-components';
import SavedImagesContext from '../contexts/SavedImagesContext';
import UserUploadContext from '../contexts/UserUploadContext';
import CaptionContext from '../contexts/CaptionContext';
import Cookies from 'js-cookie';
import '../css/fonts.css';
import IosShareIcon from '@mui/icons-material/IosShare';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import iphone7 from '../img/mockups/iphone_7.webp';
import iphone7Plus from '../img/mockups/iphone_7_plus.webp';
import iphone8 from '../img/mockups/iphone_8.webp';
import iphone8Plus from '../img/mockups/iphone_8_plus.webp';
import iphoneX from '../img/mockups/iphone_x.webp';
import iphoneXR from '../img/mockups/iphone_xr.webp';
import iphoneXS from '../img/mockups/iphone_xs.webp';
import iphoneXSMax from '../img/mockups/iphone_xs_max.webp';
import iphone11 from '../img/mockups/iphone_11.webp';
import iphone11Pro from '../img/mockups/iphone_11_pro.webp';
import iphone11ProMax from '../img/mockups/iphone_11_pro_max.webp';
import iphone12Mini from '../img/mockups/iphone_12_mini.webp';
import iphone12 from '../img/mockups/iphone_12.webp';
import iphone12Pro from '../img/mockups/iphone_12_pro.webp';
import iphone12ProMax from '../img/mockups/iphone_12_pro_max.webp';
import iphone13Mini from '../img/mockups/iphone_13_mini.webp';
import iphone13 from '../img/mockups/iphone_13.webp';
import iphone13Pro from '../img/mockups/iphone_13_pro.webp';
import iphone13ProMax from '../img/mockups/iphone_13_pro_max.webp';
import iphone14 from '../img/mockups/iphone_14.webp';
import iphone14Plus from '../img/mockups/iphone_14_plus.webp';
import iphone14Pro from '../img/mockups/iphone_14_pro.webp';
import iphone14ProMax from '../img/mockups/iphone_14_pro_max.webp';
import iphone15 from '../img/mockups/iphone_15.webp';
import iphone15Plus from '../img/mockups/iphone_15_plus.webp';
import iphone15Pro from '../img/mockups/iphone_15_pro.webp';
import iphone15ProMax from '../img/mockups/iphone_15_pro_max.webp';
import galaxyS10 from '../img/mockups/galaxy_s10.webp';
import galaxyS10E from '../img/mockups/galaxy_s10_e.webp';
import galaxyS10Plus from '../img/mockups/galaxy_s10_plus.webp';
import galaxyS20 from '../img/mockups/galaxy_s20.webp';
import galaxyS20Plus from '../img/mockups/galaxy_s20_plus.webp';
import galaxyS20Ultra from '../img/mockups/galaxy_s20_ultra.webp';
import galaxyS21 from '../img/mockups/galaxy_s21.webp';
import galaxyS21Plus from '../img/mockups/galaxy_s21_plus.webp';
import galaxyS21Ultra from '../img/mockups/galaxy_s21_ultra.webp';
import galaxyS22 from '../img/mockups/galaxy_s22.webp';
import galaxyS22Plus from '../img/mockups/galaxy_s22_plus.webp';
import galaxyS22Ultra from '../img/mockups/galaxy_s22_ultra.webp';
import galaxyS23 from '../img/mockups/galaxy_s23.webp';
import galaxyS23Plus from '../img/mockups/galaxy_s23_plus.webp';
import galaxyS23Ultra from '../img/mockups/galaxy_s23_ultra.webp';
import iphone7_small from '../img/mockups_small/iphone_7_small.webp';
import iphone7Plus_small from '../img/mockups_small/iphone_7_plus_small.webp';
import iphone8_small from '../img/mockups_small/iphone_8_small.webp';
import iphone8Plus_small from '../img/mockups_small/iphone_8_plus_small.webp';
import iphoneX_small from '../img/mockups_small/iphone_x_small.webp';
import iphoneXR_small from '../img/mockups_small/iphone_xr_small.webp';
import iphoneXS_small from '../img/mockups_small/iphone_xs_small.webp';
import iphoneXSMax_small from '../img/mockups_small/iphone_xs_max_small.webp';
import iphone11_small from '../img/mockups_small/iphone_11_small.webp';
import iphone11Pro_small from '../img/mockups_small/iphone_11_pro_small.webp';
import iphone11ProMax_small from '../img/mockups_small/iphone_11_pro_max_small.webp';
import iphone12Mini_small from '../img/mockups_small/iphone_12_mini_small.webp';
import iphone12_small from '../img/mockups_small/iphone_12_small.webp';
import iphone12Pro_small from '../img/mockups_small/iphone_12_pro_small.webp';
import iphone12ProMax_small from '../img/mockups_small/iphone_12_pro_max_small.webp';
import iphone13Mini_small from '../img/mockups_small/iphone_13_mini_small.webp';
import iphone13_small from '../img/mockups_small/iphone_13_small.webp';
import iphone13Pro_small from '../img/mockups_small/iphone_13_pro_small.webp';
import iphone13ProMax_small from '../img/mockups_small/iphone_13_pro_max_small.webp';
import iphone14_small from '../img/mockups_small/iphone_14_small.webp';
import iphone14Plus_small from '../img/mockups_small/iphone_14_plus_small.webp';
import iphone14Pro_small from '../img/mockups_small/iphone_14_pro_small.webp';
import iphone14ProMax_small from '../img/mockups_small/iphone_14_pro_max_small.webp';
import iphone15_small from '../img/mockups_small/iphone_15_small.webp';
import iphone15Plus_small from '../img/mockups_small/iphone_15_plus_small.webp';
import iphone15Pro_small from '../img/mockups_small/iphone_15_pro_small.webp';
import iphone15ProMax_small from '../img/mockups_small/iphone_15_pro_max_small.webp';
import galaxyS10_small from '../img/mockups_small/galaxy_s10_small.webp';
import galaxyS10E_small from '../img/mockups_small/galaxy_s10_e_small.webp';
import galaxyS10Plus_small from '../img/mockups_small/galaxy_s10_plus_small.webp';
import galaxyS20_small from '../img/mockups_small/galaxy_s20_small.webp';
import galaxyS20Plus_small from '../img/mockups_small/galaxy_s20_plus_small.webp';
import galaxyS20Ultra_small from '../img/mockups_small/galaxy_s20_ultra_small.webp';
import galaxyS21_small from '../img/mockups_small/galaxy_s21_small.webp';
import galaxyS21Plus_small from '../img/mockups_small/galaxy_s21_plus_small.webp';
import galaxyS21Ultra_small from '../img/mockups_small/galaxy_s21_ultra_small.webp';
import galaxyS22_small from '../img/mockups_small/galaxy_s22_small.webp';
import galaxyS22Plus_small from '../img/mockups_small/galaxy_s22_plus_small.webp';
import galaxyS22Ultra_small from '../img/mockups_small/galaxy_s22_ultra_small.webp';
import galaxyS23_small from '../img/mockups_small/galaxy_s23_small.webp';
import galaxyS23Plus_small from '../img/mockups_small/galaxy_s23_plus_small.webp';
import galaxyS23Ultra_small from '../img/mockups_small/galaxy_s23_ultra_small.webp';

const goAway = keyframes`
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: .2;
    transform: scale(.95);
  }
`

const popupFadeOut = keyframes`
  from {
    opacity 1;
  }
  to {
    opacity: 0;
  }
`

const pulse = keyframes`
    0% { opacity: 0.5; }
    50% { opacity: 1; }
    100% { opacity: 0.5; }
`

const BigBigBox = styled.div`
  animation: ${({ animate }) => (animate ? css`${goAway} 0.25s forwards` : 'none')};
`

const Container = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  margin-bottom: 20px;
  @media (max-width: 800px) {
    grid-template-columns: 100%;
  }
`;

const RightContainer = styled.div`
  position: relative;
  width: 80%;
  max-width: 800px;
  margin-top: 2rem;
  @media (max-width: 800px) {
    margin-right: auto;
    margin-left: auto;
    width: 88%;
    margin-top: 2rem;
  }
`

const LeftContainer = styled.div`
  position: relative;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
`

const BarForTwoCircleButtons = styled.div`
  margin-top: 10px;
  display: ${({render}) => (render ? 'flex' : 'none')};
  justify-content: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 800px) {
    width: 88%;
    margin-top: -160px;
    justify-content: flex-end;
  }
`

const TwoCircleButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 5;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`

const FavButton = styled.button`
  border: none;
  border-radius: 50%;
  height: 3.5rem;
  width: 3.5rem;
  min-width: 3.5rem;
  max-width: 3.5rem;
  min-height: 3.5rem;
  max-height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  margin-bottom: 0px;
  background: #f0f0f0;
  margin-right: 10px;
  box-sizing: border-box;
  padding: 0;
  color: ${({valid}) => (valid ? '#fd1d1d' : 'black')   };
  &:hover {
    transform: scale(1.1);
    transition: all 0.05s ease-in-out;
  }
  &:active {
    transform: scale(.95);
    transition: all 0.05s ease-in-out;
  }
  @media (max-width: 800px) {
    margin-bottom: 10px;
    margin-right: 0px;
  }
`

const ShareButton = styled.button`
  border: none;
  border-radius: 50%;
  height: 3.5rem;
  width: 3.5rem;
  min-width: 3.5rem;
  max-width: 3.5rem;
  min-height: 3.5rem;
  max-height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  background: #f0f0f0;
  margin-left: 10px;
  color: black;
  box-sizing: border-box;
  padding: 0;
  &:hover {
    transform: scale(1.1);
    transition: all 0.05s ease-in-out;
  }
  &:active {
    transform: scale(.95);
    transition: all 0.05s ease-in-out;
  }
  @media (max-width: 800px) {
    margin-left: 0px;
  }
`

const Wrapper = styled.div`
  padding: 0;
  margin-bottom: 20px;
`

const TimelineContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const TimelineEvent = styled.div`
  position: relative;
  margin-bottom: 15px;
  min-height: 60px;
  padding-bottom: 6px;
`

const TimelineLine = styled.div`
  border-left: 2px solid ${props => props.isValid ? '#4CAF50' : '#bbb'};
  height: 100%;
  position: absolute;
  left: 15px;
`

const TimelineIcon = styled.div`
  width: 30px;
  height: 30px;
  background-color: ${props => props.isValid ? '#4CAF50' : '#bbb'};
  border: 2px solid ${props => props.isValid ? '#4CAF50' : '#bbb'};
  border-radius: 50%;
  position: absolute;
  left: -1px;
  top: -15px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TimelineIconNumber = styled.div`
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 18px;
  color: #eee;
  @media (max-width: 800px) {
    font-size: 18px;
  }
`

const TimelineContent = styled.div`
  padding-left: 55px;
  margin-bottom: 2rem;
  margin-top: -15px;
`;

const Label = styled.label`
  font-family: 'Poppins', sans-serif;
  font-size: 1.5rem;
  font-weight: bold;
  color: black;
  display: block;
  margin-bottom: .7rem;
`

const LabelNoMarginTop = styled.label`
    font-family: 'Poppins', sans-serif;
    font-size: 1.5rem;
    font-weight: bold;
    display: block;
    margin-bottom: .7rem;
`

const LabelNoMarginBottom = styled.label`
  font-family: 'Poppins', sans-serif;
  font-size: 1.5rem;
  font-weight: bold;
  display: block;
  color: black;
`

const HelpfulText = styled.div`
  font-family: 'Poppins', sans-serif;
  font-size: .8rem;
  display: block;
  color: #5e5e5e;
  margin-bottom: 1rem;
  display: flex;
  justify-content: left;
  gap: 4px;
  align-items: center;
`

const HelpfulTextNoMarginBottom = styled.div`
  font-family: 'Poppins', sans-serif;
  font-size: .8rem;
  display: block;
  color: #5e5e5e;
  display: flex;
  justify-content: left;
  gap: 4px;
  align-items: center;
`

const LabelSpan = styled.span`
  font-family: 'Poppins', sans-serif;
  display: inline;
  font-size: 1.3rem;
  font-weight: bold;
  display: block;
  margin-bottom: .7rem;
`

const PromptContainer = styled.div`
  padding: 10px;
  background: linear-gradient(80deg, #abd3ed, #cdf4dd);
  border-radius: 10px;
  border: 1px solid #999;
`

const BottomButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const GenerateButton = styled.button`
  --color1: ${({ valid }) => (valid ? '#405de6' : 'grey')};
  --color2: ${({ valid }) => (valid ? '#5851db' : 'grey')};
  --color3: ${({ valid }) => (valid ? '#833ab4' : 'grey')};
  --color4: ${({ valid }) => (valid ? '#c13584' : 'grey')};
  --color5: ${({ valid }) => (valid ? '#e1306c' : 'grey')};
  --color6: ${({ valid }) => (valid ? '#fd1d1d' : 'grey')};
  font-family: 'Poppins', sans-serif;
  width: 100%;
  max-width: 200px;
  font-size: 1rem;
  font-weight: bold;
  padding: 0.75rem;
  color: #ffffff;
  background: linear-gradient(80deg, var(--color1), var(--color2), var(--color3), var(--color4), var(--color5), var(--color6));
  border: none;
  border-radius: 30px;
  display: flex;
  height: 50px;
  transition: all .2s ease-in-out;
  align-items: center;
  justify-content: center;
  margin-top: 0;
  &:hover {
    transform: scale(1.04);
    transition: all 0.05s ease-in-out;
  }
  &:active {
    transform: scale(.95);
    transition: all 0.05s ease-in-out;
  }
`;

const ImWorkingHereButton = styled.button`
  font-family: 'Poppins', sans-serif;
  width: 100%;
  max-width: 200px;
  height: 50px;
  font-size: 1rem;
  font-weight: bold;
  padding: 0.75rem;
  color: #ffffff;
  background: linear-gradient(80deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d);
  border: none;
  border-radius: 30px;
  display: flex;
  transition: all .2s ease-in-out;
  align-items: center;
  justify-content: center;
  margin-top: 0;
`;

const FileChooserButton = styled.div`
    font-family: 'Poppins', sans-serif;
    border: none;
    font-size: 16px;
    margin-top: 6px;
    margin-bottom: 2rem;
    color: #4dabf7;
    cursor: pointer;

    @media (max-width: 600px) {
      font-size: 16px;
    }
`

const HiddenInput = styled.input`
    display: none;
`

const TextArea = styled.textarea`
  font-family: 'Poppins';
  border: none;
  resize: none;
  width: 100%;
  height: 5.5rem;
  border-radius: 10px;
  padding: 8px;
  font-size: 16px;
  box-sizing: border-box;
  background-color: #f4f4f5;
  z-index: 3;
  color: black;
  ::placeholder {
    color: #7d7d85;
  }
  &:focus {
    outline: none;
  }
  &:active {
    outline: none;
  }
  @media (max-width: 800px) {
    height: 5.5rem;
  }
`;

const SelectContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  @media (max-width: 470px) {
    flex-direction: column;
  }
`

const NewSelect1 = styled(Select)`
  box-sizing: border-box;
  width: 9rem;
  max-width: 9rem;
  margin-right: 0;
  @media (max-width: 400px) {

  }
`

const NewSelect2 = styled(Select)`
  box-sizing: border-box;
  width: 11rem;
  max-width: 11rem;
  margin-right: 0;
  @media (max-width: 400px) {

  }
`

const ControlsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  @media (max-width: 400px) {
    margin-bottom: 12px;
  }
`

const ResetButton = styled.button`
  border: none;
  border-radius: 30px;
  height: 3.5rem;
  max-height: 3.5rem;
  width: 9rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 0px;
  background: #f0f0f0;
  box-sizing: border-box;
  padding: 0;
  @media (max-width: 400px) {
    font=size: 14px;
    height: 3rem;
  }
`

const SwatchContainer = styled.div`
  display: flex;
  gap: 12px;
`

const ColorSwatch = styled.div`
  border-radius: 50%;
  border: none;
  height: 32px;
  width: 32px;
  background: ${props => props.color};
  border: 1px solid ${props => props.color === '#FFFFFF' ? '#000000' : props.color};
  display: flex;
  justify-content: center;
  align-items: center;
  &:focus {
    outline: none;
  }
  &:active {
    outline: none;
  }
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
`

const ProceedButton = styled.button`
  font-family: 'Poppins', sans-serif;
  width: 100%;
  font-size: 1rem;
  font-weight: bold;
  padding: 0.75rem;
  color: #ffffff;
  background: ${({valid}) => (valid ? '  linear-gradient(80deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d)' : 'grey')};
  border: none;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2.5rem;
  transition: all .2s ease-in-out;
  &:hover {
    transform: scale(1.04);
    transition: all 0.05s ease-in-out;
  }
  &:active {
      transform: scale(.95);
      transition: all 0.05s ease-in-out;
  }
  @media (max-width: 600px) {
    margin-top: 2.5rem;
  }
`;

const RestartText = styled.span`
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  @media (max-width: 600px) {
    font-size: 12px;
  }
`

const RestartButton = styled.button`
  background: none;
  border: none;
  margin-top: 16px;
  font-size: 14px;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  text-decoration: underline;
  &:hover {
    color: #fd1d1d;
    transform: scale(1.05);
    transition: all 0.05s ease-in-out;
  }
  &:active {
    color: #fd1d1d;
    transform: scale(.97);
    transition: all 0.05s ease-in-out;
  }
  @media (max-width: 600px) {
    font-size: 12px;
  }
`

const Dot = styled.div`
    animation: ${pulse} 1s infinite;
    width: 10px;
    height: 10px;
    margin: 0 5px;
    background-color: white;
    border-radius: 50%;
`;

const ErrorPopup = styled.div`
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 20px;
  background: linear-gradient(80deg, #c13584, #e1306c, #fd1d1d);
  opacity: 1;
  color: white;
  z-index: 1000;
  border-radius: 20px;
  transition: opacity 1s ease-out;
  animation: ${popupFadeOut} 2s forward;
  width: 80%;
  max-width: 600px;
`

const Popup = styled.div`
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 20px;
  background-color: #333;
  opacity: 1;
  color: white;
  z-index: 1000;
  border-radius: 20px;
  transition: opacity 1s ease-out;
  animation: ${popupFadeOut} 2s forward;
  width: 80%;
  max-width: 600px;
`

const PopupText = styled.div`
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  @media (max-width: 600px) {
    font-size: 16px;
  }
`

const CustomizeFromUpload = () => {
    const [selectedSize, setSelectedSize] = useState('iPhone 15');
    const [canvasWidth, setCanvasWidth] = useState(window.width > 800 ? Math.min(document.documentElement.clientWidth/2, window.innerHeight-180) : Math.min(document.documentElement.clientWidth, 400));
    const [showLeftContainer, setShowLeftContainer] = useState(window.innerWidth > 800 ? true : false);
    const selectedSizeRef = useRef(selectedSize);
    const sizeRef = useRef(null);
    const eventSourceRef = useRef(null);
    const navigate = useNavigate();
    const { savedImages, setSavedImages } = useContext(SavedImagesContext);
    const [animate, setAnimate] = useState(false);
    const {userUpload, setUserUpload} = useContext(UserUploadContext);
    const {caption, setCaption} = useContext(CaptionContext);
    const fileInputRef = useRef(null);
    
    const [showErrorPopup, setShowErrorPopup] = useState(false);
    const [showCopyPopup, setShowCopyPopup] = useState(false);
    const [showMaxFavoritePopup, setShowMaxFavoritePopup] = useState(false);

    const iPhoneSizes = ["iPhone 7", "iPhone 7 Plus", "iPhone 8", "iPhone 8 Plus", "iPhone X", "iPhone XR", "iPhone XS", "iPhone XS Max", "iPhone 11", "iPhone 11 Pro", "iPhone 11 Pro Max", "iPhone SE (2020)", "iPhone 12 mini", "iPhone 12", "iPhone 12 Pro", "iPhone 12 Pro Max", "iPhone 13 mini", "iPhone 13", "iPhone 13 Pro", "iPhone 13 Pro Max", "iPhone 14", "iPhone 14 Plus", "iPhone 14 Pro", "iPhone 14 Pro Max", "iPhone 15", "iPhone 15 Plus", "iPhone 15 Pro", "iPhone 15 Pro Max"];
    const samsungSizes = ["Galaxy S10", "Galaxy S10+", "Galaxy S10e", "Galaxy S20", "Galaxy S20 Plus", "Galaxy S20 Ultra", "Galaxy S21", "Galaxy S21 Plus", "Galaxy S21 Ultra", "Galaxy S22", "Galaxy S22 Plus", "Galaxy S22 Ultra", "Galaxy S23", "Galaxy S23 Plus", "Galaxy S23 Ultra"];
    const [sizes, setSizes] = useState(iPhoneSizes);
    const makes = ['Apple', 'Samsung'];
    const [make, setMake] = useState('Apple');
    const [makeOptions, setMakeOptions] = useState([
      { value: 'Apple', label: 'Apple' },
      { value: 'Samsung', label: 'Samsung' }
    ]);
    const [sizeOptions, setSizeOptions] = useState([
      { value: 'iPhone 7', label: 'iPhone 7' },
      { value: 'iPhone 7 Plus', label: 'iPhone 7 Plus' },
      { value: 'iPhone 8', label: 'iPhone 8' },
      { value: 'iPhone 8 Plus', label: 'iPhone 8 Plus' },
      { value: 'iPhone X', label: 'iPhone X' },
      { value: 'iPhone XR', label: 'iPhone XR' },
      { value: 'iPhone XS', label: 'iPhone XS' },
      { value: 'iPhone XS Max', label: 'iPhone XS Max' },
      { value: 'iPhone 11', label: 'iPhone 11' },
      { value: 'iPhone 11 Pro', label: 'iPhone 11 Pro' },
      { value: 'iPhone 11 Pro Max', label: 'iPhone 11 Pro Max' },
      { value: 'iPhone SE (2020)', label: 'iPhone SE (2020)' },
      { value: 'iPhone 12 mini', label: 'iPhone 12 mini' },
      { value: 'iPhone 12', label: 'iPhone 12' },
      { value: 'iPhone 12 Pro', label: 'iPhone 12 Pro' },
      { value: 'iPhone 12 Pro Max', label: 'iPhone 12 Pro Max' },
      { value: 'iPhone 13 mini', label: 'iPhone 13 mini' },
      { value: 'iPhone 13', label: 'iPhone 13' },
      { value: 'iPhone 13 Pro', label: 'iPhone 13 Pro' },
      { value: 'iPhone 13 Pro Max', label: 'iPhone 13 Pro Max' },
      { value: 'iPhone 14', label: 'iPhone 14' },
      { value: 'iPhone 14 Plus', label: 'iPhone 14 Plus' },
      { value: 'iPhone 14 Pro', label: 'iPhone 14 Pro' },
      { value: 'iPhone 14 Pro Max', label: 'iPhone 14 Pro Max' },
      { value: 'iPhone 15', label: 'iPhone 15' },
      { value: 'iPhone 15 Plus', label: 'iPhone 15 Plus' },
      { value: 'iPhone 15 Pro', label: 'iPhone 15 Pro' },
      { value: 'iPhone 15 Pro Max', label: 'iPhone 15 Pro Max' }
    ]);
    const [styles, setStyles] = useState(["No Style", "Watercolor", "Anime", "Leonid Afremov", "Expressionist", "Pop Art", "Van Gogh Starry Night", "Constructivist", "Psychedelic", "Doodle", "Stained Glass", "Grunge", "Art Nouveau", "Papercraft Collage", "Alma Thomas", "Abstract", "Vaporwave", "Pixel Art", "Illustration", "Photographic", "Pointillism", "Comic Book", "Oil Painting", "Children's Storybook", "Grayscale", "Colored Pencils", "Crayon Artwork"]); // mixed results with papercraft collage. I want to include ukiyo-e somehow
    const [style, setStyle] = useState('No Style');
    const [fill, setFill] = useState('#FFFFFF');
    const presetFills = [''];
    const [print, printStatus] = useImage(userUpload ? userUpload[0] : null);
    const [printX, setPrintX] = useState(0);
    const [printY, setPrintY] = useState(0);
    const [printWidth, setPrintWidth] = useState(0);
    const [printHeight, setPrintHeight] = useState(0);
    const [displayedPrintWidth, setDisplayedPrintWidth] = useState(0);
    const [displayedPrintHeight, setDisplayedPrintHeight] = useState(0);
    const printRef = useRef(null);
    const transformerRef = useRef(null);
    const [isTransformerVisible, setIsTransformerVisible] = useState(true);

    // map from selectedSize to imported images
    const caseImagePaths = window.innerWidth > 800 ? {
        'iPhone 15': iphone15,
        'iPhone 15 Plus': iphone15Plus,
        'iPhone 15 Pro': iphone15Pro,
        'iPhone 15 Pro Max': iphone15ProMax,
        'iPhone 14': iphone14,
        'iPhone 14 Plus': iphone14Plus,
        'iPhone 14 Pro': iphone14Pro,
        'iPhone 14 Pro Max': iphone14ProMax,
        'iPhone 13': iphone13,
        'iPhone 13 mini': iphone13Mini,
        'iPhone 13 Pro': iphone13Pro,
        'iPhone 13 Pro Max': iphone13ProMax,
        'iPhone 12': iphone12,
        'iPhone 12 mini': iphone12Mini,
        'iPhone 12 Pro': iphone12Pro,
        'iPhone 12 Pro Max': iphone12ProMax,
        'iPhone 11': iphone11,
        'iPhone 11 Pro': iphone11Pro,
        'iPhone 11 Pro Max': iphone11ProMax,
        'iPhone X': iphoneX,
        'iPhone XS': iphoneXS,
        'iPhone XS Max': iphoneXSMax,
        'iPhone XR': iphoneXR,
        'iPhone 7': iphone7,
        'iPhone 8': iphone8,
        'iPhone 7 Plus': iphone7Plus,
        'iPhone 8 Plus': iphone8Plus,
        'Galaxy S10': galaxyS10,
        'Galaxy S10e': galaxyS10E,
        'Galaxy S10+': galaxyS10Plus,
        'Galaxy S20': galaxyS20,
        'Galaxy S20 Plus': galaxyS20Plus,
        'Galaxy S20 Ultra': galaxyS20Ultra,
        'Galaxy S21': galaxyS21,
        'Galaxy S21 Plus': galaxyS21Plus,
        'Galaxy S21 Ultra': galaxyS21Ultra,
        'Galaxy S22': galaxyS22,
        'Galaxy S22 Plus': galaxyS22Plus,
        'Galaxy S22 Ultra': galaxyS22Ultra,
        'Galaxy S23': galaxyS23,
        'Galaxy S23 Plus': galaxyS23Plus,
        'Galaxy S23 Ultra': galaxyS23Ultra
    } : {
        'iPhone 15': iphone15_small,
        'iPhone 15 Plus': iphone15Plus_small,
        'iPhone 15 Pro': iphone15Pro_small,
        'iPhone 15 Pro Max': iphone15ProMax_small,
        'iPhone 14': iphone14_small,
        'iPhone 14 Plus': iphone14Plus_small,
        'iPhone 14 Pro': iphone14Pro_small,
        'iPhone 14 Pro Max': iphone14ProMax_small,
        'iPhone 13': iphone13_small,
        'iPhone 13 mini': iphone13Mini_small,
        'iPhone 13 Pro': iphone13Pro_small,
        'iPhone 13 Pro Max': iphone13ProMax_small,
        'iPhone 12': iphone12_small,
        'iPhone 12 mini': iphone12Mini_small,
        'iPhone 12 Pro': iphone12Pro_small,
        'iPhone 12 Pro Max': iphone12ProMax_small,
        'iPhone 11': iphone11_small,
        'iPhone 11 Pro': iphone11Pro_small,
        'iPhone 11 Pro Max': iphone11ProMax_small,
        'iPhone X': iphoneX_small,
        'iPhone XS': iphoneXS_small,
        'iPhone XS Max': iphoneXSMax_small,
        'iPhone XR': iphoneXR_small,
        'iPhone 7': iphone7_small,
        'iPhone 8': iphone8_small,
        'iPhone 7 Plus': iphone7Plus_small,
        'iPhone 8 Plus': iphone8Plus_small,
        'Galaxy S10': galaxyS10_small,
        'Galaxy S10e': galaxyS10E_small,
        'Galaxy S10+': galaxyS10Plus_small,
        'Galaxy S20': galaxyS20_small,
        'Galaxy S20 Plus': galaxyS20Plus_small,
        'Galaxy S20 Ultra': galaxyS20Ultra_small,
        'Galaxy S21': galaxyS21_small,
        'Galaxy S21 Plus': galaxyS21Plus_small,
        'Galaxy S21 Ultra': galaxyS21Ultra_small,
        'Galaxy S22': galaxyS22_small,
        'Galaxy S22 Plus': galaxyS22Plus_small,
        'Galaxy S22 Ultra': galaxyS22Ultra_small,
        'Galaxy S23': galaxyS23_small,
        'Galaxy S23 Plus': galaxyS23Plus_small,
        'Galaxy S23 Ultra': galaxyS23Ultra_small
    };
    const [caseImagePath, setCaseImagePath] = useState(caseImagePaths[selectedSize]);
    
    // map from selectedSize to height, x,
    

    // reset case image path when selected size changes
    useEffect(() => {
        setCaseImagePath(caseImagePaths[selectedSize]);
    }, [selectedSize]);
    const [caseImage, caseImageStatus] = useImage(caseImagePath);

    useEffect(() => {
      if(print) {
          const initialHeight = 180;
          const widthHeightRatio = print.width / print.height;
          setPrintHeight(initialHeight);
          setPrintWidth(initialHeight * widthHeightRatio);
          setDisplayedPrintHeight(initialHeight);
          setDisplayedPrintWidth(initialHeight * widthHeightRatio);
          setPrintX(((canvasWidth*(1748/2480))-(initialHeight*widthHeightRatio))/2);
          setPrintY(140);
      }
    }, [print]);

    const originalCanvasStyling = window.innerWidth > 800 ? {
        'top': '60',
        'left': '0',
        'marginLeft': 'auto',
        'marginRight': 'auto',
        'marginTop': '20px',
        'objectFit': 'contain',
        'justifyContent': 'center',
        'alignItems': 'center',
        'zIndex': 0,
        'height': canvasWidth,
    } : {
        'position': 'relative',
        'marginRight': 'auto',
        'marginLeft': 'auto',
        'marginTop': '0px',
        'objectFit': 'contain',
        'alignItems': 'center',
        'justifyContent': 'center',
        'height': canvasWidth,
    };
    const [canvasStyling, setCanvasStyling] = useState(originalCanvasStyling);

    const originalSkeletonStyling = window.innerWidth > 800 ? {
        'top': '60',
        'left': '0',
        'marginLeft': 'auto',
        'marginRight': 'auto',
        'objectFit': 'contain',
        'display': 'flex',
        'justifyContent': 'center',
        'alignItems': 'center',
        'zIndex': 0
    } : {
        'position': 'relative',
        'marginRight': 'auto',
        'marginLeft': 'auto',
        'objectFit': 'contain',
        'display': 'flex',
        'alignItems': 'center',
        'justifyContent': 'center'
    }
    const [skeletonStyling, setSkeletonStyling] = useState(originalSkeletonStyling);

    const selectStyles = {
      control: (base) => ({
        ...base,
        border: 'none',
        borderRadius: '30px',
        boxShadow: 'none',
        boxSizing: 'border-box',
        height: '50px',
        appearance: 'none',
        backgroundColor: '#f4f4f5',
        textAlign: 'center',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        paddingLeft: '18px'
      }),
      option: (base) => ({
        ...base,
        color: '#000000',
        fontFamily: "'Poppins', sans-serif",
        fontSize: '16px'
      }),
      placeholder: (base) => ({
        ...base,
        color: '#000000',
        fontFamily: "'Poppins', sans-serif",
        fontSize: '16px',
      })
    };

    // scroll to the top -- since we are using pages now instead of switching components on a single page, this is probably unnecessary now
    useEffect(() => {
        window.scrollTo(0,0);
    }, []);

    // attaches the transformer to the print image for resizing
    useEffect(() => {
        if (printRef.current && isTransformerVisible) {
          transformerRef.current.nodes([printRef.current]);
          transformerRef.current.getLayer().batchDraw();
        }
    });

    // this updates the canvasWidth and showLeftContainer state variables upon resize, and resizes the skeleton
    useEffect(() => {
        const handleResize = () => {
            setCanvasWidth(window.innerWidth > 800 ? Math.min(document.documentElement.clientWidth/2, window.innerHeight-180) : Math.min(document.documentElement.clientWidth, 400));
            setSkeletonStyling(window.innerWidth > 800 ? {
                'top': '60',
                'left': '0',
                'marginLeft': 'auto',
                'marginRight': 'auto',
                'objectFit': 'contain',
                'display': 'flex',
                'justifyContent': 'center',
                'alignItems': 'center',
                'zIndex': 0
            } : {
                'position': 'relative',
                'marginRight': 'auto',
                'marginLeft': 'auto',
                'objectFit': 'contain',
                'display': 'flex',
                'alignItems': 'center',
                'justifyContent': 'center'
            });
            setCanvasStyling(window.innerWidth > 800 ? {
                'top': '60',
                'left': '0',
                'marginLeft': 'auto',
                'marginRight': 'auto',
                'marginTop': '20px',
                'objectFit': 'contain',
                'justifyContent': 'center',
                'alignItems': 'center',
                'zIndex': 0,
                'height': window.innerWidth > 800 ? Math.min(document.documentElement.clientWidth/2, window.innerHeight-180) : Math.min(document.documentElement.clientWidth, 400),
            } : {
                'position': 'relative',
                'marginRight': 'auto',
                'marginLeft': 'auto',
                'marginTop': '0px',
                'objectFit': 'contain',
                'alignItems': 'center',
                'justifyContent': 'center',
                'height': window.innerWidth > 800 ? Math.min(document.documentElement.clientWidth/2, window.innerHeight-180) : Math.min(document.documentElement.clientWidth, 400),
            });
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, []);

    
    // updates the selectedSizeRef when selectedSize changes
    useEffect(() => {
      selectedSizeRef.current = selectedSize;
    }, [selectedSize]);

    //this will close the generateImage server connection if the component unmounts
    useEffect(() => {
      return () => {
        if (eventSourceRef.current) {
          eventSourceRef.current.close();
        }
      };
    }, []);

    const handleMakeChange = (selectedOption) => {
      setMake(selectedOption.value);
      if (selectedOption.value === 'Apple') {
        setSizes(iPhoneSizes);
        setSizeOptions([
          { value: 'iPhone 7', label: 'iPhone 7' },
          { value: 'iPhone 7 Plus', label: 'iPhone 7 Plus' },
          { value: 'iPhone 8', label: 'iPhone 8' },
          { value: 'iPhone 8 Plus', label: 'iPhone 8 Plus' },
          { value: 'iPhone X', label: 'iPhone X' },
          { value: 'iPhone XR', label: 'iPhone XR' },
          { value: 'iPhone XS', label: 'iPhone XS' },
          { value: 'iPhone XS Max', label: 'iPhone XS Max' },
          { value: 'iPhone 11', label: 'iPhone 11' },
          { value: 'iPhone 11 Pro', label: 'iPhone 11 Pro' },
          { value: 'iPhone 11 Pro Max', label: 'iPhone 11 Pro Max' },
          { value: 'iPhone SE (2020)', label: 'iPhone SE (2020)' },
          { value: 'iPhone 12 mini', label: 'iPhone 12 mini' },
          { value: 'iPhone 12', label: 'iPhone 12' },
          { value: 'iPhone 12 Pro', label: 'iPhone 12 Pro' },
          { value: 'iPhone 12 Pro Max', label: 'iPhone 12 Pro Max' },
          { value: 'iPhone 13 mini', label: 'iPhone 13 mini' },
          { value: 'iPhone 13', label: 'iPhone 13' },
          { value: 'iPhone 13 Pro', label: 'iPhone 13 Pro' },
          { value: 'iPhone 13 Pro Max', label: 'iPhone 13 Pro Max' },
          { value: 'iPhone 14', label: 'iPhone 14' },
          { value: 'iPhone 14 Plus', label: 'iPhone 14 Plus' },
          { value: 'iPhone 14 Pro', label: 'iPhone 14 Pro' },
          { value: 'iPhone 14 Pro Max', label: 'iPhone 14 Pro Max' },
          { value: 'iPhone 15', label: 'iPhone 15' },
          { value: 'iPhone 15 Plus', label: 'iPhone 15 Plus' },
          { value: 'iPhone 15 Pro', label: 'iPhone 15 Pro' },
          { value: 'iPhone 15 Pro Max', label: 'iPhone 15 Pro Max' }
        ]);
        setSelectedSize('iPhone 15');
      } else if (selectedOption.value === 'Samsung') {
        setSizes(samsungSizes);
        setSizeOptions([
          { value: 'Galaxy S10', label: 'Galaxy S10' },
          { value: 'Galaxy S10+', label: 'Galaxy S10+' },
          { value: 'Galaxy S10e', label: 'Galaxy S10e' },
          { value: 'Galaxy S20', label: 'Galaxy S20' },
          { value: 'Galaxy S20 Plus', label: 'Galaxy S20 Plus' },
          { value: 'Galaxy S20 Ultra', label: 'Galaxy S20 Ultra' },
          { value: 'Galaxy S21', label: 'Galaxy S21' },
          { value: 'Galaxy S21 Plus', label: 'Galaxy S21 Plus' },
          { value: 'Galaxy S21 Ultra', label: 'Galaxy S21 Ultra' },
          { value: 'Galaxy S22', label: 'Galaxy S22' },
          { value: 'Galaxy S22 Plus', label: 'Galaxy S22 Plus' },
          { value: 'Galaxy S22 Ultra', label: 'Galaxy S22 Ultra' },
          { value: 'Galaxy S23', label: 'Galaxy S23' },
          { value: 'Galaxy S23 Plus', label: 'Galaxy S23 Plus' },
          { value: 'Galaxy S23 Ultra', label: 'Galaxy S23 Ultra' },
        ]);
        setSelectedSize('Galaxy S23');
      }
    }

    // this changes the phone model
    const handleSizeChange = async (selectedOption) => {
      setSelectedSize(selectedOption.value);
    }

    // this changes the selected fill color
    const handleFillChange = async (selectedColor) => {
      setFill(selectedColor);
    };

    // this changes the selected style
    const handleStyleChange = async (event) => {
      setStyle(event.target.value);
    }

    // to reset the image back to its default placement
    const reset = () => {
      if (print && printRef.current) {
        const initialHeight = 180;
        const widthHeightRatio = print.width / print.height;
        const newWidth = initialHeight * widthHeightRatio;
        setPrintHeight(initialHeight);
        setPrintWidth(newWidth);
        setDisplayedPrintHeight(initialHeight);
        setDisplayedPrintWidth(initialHeight * widthHeightRatio);
        setPrintX(((canvasWidth * (1748 / 2480)) - newWidth) / 2);
        setPrintY(140);
    
        const node = printRef.current;
        node.setAttr('width', newWidth);
        node.setAttr('height', initialHeight);
        node.setAttr('x', ((canvasWidth * (1748 / 2480)) - newWidth) / 2);
        node.setAttr('y', 140);
        node.setAttr('scaleX', 1);
        node.setAttr('scaleY', 1);
        node.setAttr('rotation', 0);
    

        if(transformerRef.current) {
          transformerRef.current.forceUpdate();
        }
        node.getLayer().batchDraw();
      }
    };

    const toggleTransformer = () => {
      setIsTransformerVisible(!isTransformerVisible);
    }

    // this function is responsible for the snap-to-center mechanic
    const printDragBoundFunc = (pos) => {
      const canvasCenter = canvasWidth * (1748 / 2480) / 2;
      const imageHorizontalCenter = displayedPrintWidth / 2;
      const snapDistance = 6;
      const distance = Math.abs(pos.x + imageHorizontalCenter - canvasCenter);

      if (distance < snapDistance) {
        return {
          x: canvasCenter - imageHorizontalCenter,
          y: pos.y
        };
      }

      return pos;
    };

    // updates printHeight and printWidth upon resize using the border/boxes supplied by the "Transformer"
    const onTransformEnd = () => {
      const node = printRef.current;
      setDisplayedPrintWidth(node.width() * node.scaleX());
      setDisplayedPrintHeight(node.height() * node.scaleY());
    };

      // generates new image
      // const generateImage = async () => {
      //   setImWorkingHere(true);
      //   const generateUrl = process.env.REACT_APP_ENVIRONMENT === 'production' ? 'https://api.doodlebug.me/api/generate?prompt=' : 'http://localhost:3001/api/generate?prompt=';
      //   const eventSource = new EventSource(generateUrl + encodeURIComponent(prompt.substring(0,1947)) + (style !== 'No Style' ? `&style=${style}` : ''));
      //   eventSourceRef.current = eventSource;
      //   eventSource.addEventListener('success', (event) => {
      //     try {
      //       const cookieDomain = process.env.REACT_APP_ENVIRONMENT === 'production' ? '.doodlebug.me' : null;
      //       Cookies.set('imageUrl', event.data, { expires: 7 , domain: cookieDomain });
      //     } catch (error) {
      //       console.log(error);
      //     }
      //     eventSource.close();
      //   });
    // 
      //   eventSource.addEventListener('failure', (event) => {
      //     setImWorkingHere(false);
      //     setShowErrorPopup(true);
      //     eventSource.close();
      //     setTimeout(() => {
      //       setShowErrorPopup(false);
      //     }, 3000);
      //   });
      // }
      
      // const addToFavorites = () => {
      //   if (savedImages.length < 12 && print && !imWorkingHere) {
      //     const newSavedImages = savedImages.slice();
      //     if ( newSavedImages.indexOf(imageUrl) === -1) {
      //         newSavedImages.unshift(imageUrl);
      //     } else {
      //       const index = newSavedImages.indexOf(imageUrl);
      //       newSavedImages.splice(index, 1);
      //     }
      //     setSavedImages(newSavedImages);
      //     const cookieDomain = process.env.REACT_APP_ENVIRONMENT === 'production' ? '.doodlebug.me' : null;
      //     Cookies.set('savedImages', JSON.stringify(newSavedImages), { domain: cookieDomain });
      //   } else if (savedImages.length >= 12) {
      //     setShowMaxFavoritePopup(true);
      //     setTimeout(() => {
      //       setShowMaxFavoritePopup(false);
      //     }, 3000);
      //   }
      // }

      function clickTheHiddenFileInput() {
        fileInputRef.current.click();
      }

      const handleCaptionChange = (event) => {
        setCaption(event.target.value);
        // event.target.style.height = '21px';
        // event.target.style.height = `${event.target.scrollHeight}px`; // Adjust height based on content
      };

      const moveThePrint = (event) => {
        if(isTransformerVisible) {
          setPrintX(event.target.x());
          setPrintY(event.target.y());
        }
      };
    
      function handleUploadAndNavigateToCustomize(event) {
        const file = event.target.files[0];
        const validImageTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/webp', 'image/heic'];
        // file type check
        if (!validImageTypes.includes(file.type)) {
            alert('This image format is not supported. Please upload a image in JPEG, PNG, WEBP, or HEIC format.');
            return;
        }
        // file size check
        if (file.size > 12 * 1024 * 1024) {
            alert('This image file is too big. Please upload an image file smaller than 12MB.');
            return;
        }
        // file extension check
        const fileExtension = file.name.split('.').pop();
        const validExtensions = ['jpeg', 'jpg', 'png', 'heic', 'webp'];
        if (!validExtensions.includes(fileExtension)) {
            alert('This image format is not supported. Please upload a image in JPEG, PNG, WEBP, or HEIC format.');
            return;
        }
        const fileUrl = URL.createObjectURL(file);
        setUserUpload([fileUrl, file]);

        const formData = new FormData();
        formData.append('image', file);
        const captionUrl = process.env.REACT_APP_ENVIRONMENT === 'production' ? 'https://api.doodlebug.me/api/caption' : 'http://localhost:3001/api/caption';
        const requestOptions = {
            method: 'POST',
            body: formData,
            credentials: 'include'
        };
        setCaption('generating auto-caption...');
        async function getCaption () {
            await fetch(captionUrl, requestOptions)
            .then(response => response.json())
            .then(data => setCaption(data.caption))
            .catch(err => {
                console.error(err);
                setCaption('');
            });
        };
        getCaption();
      }


    return (
        <BigBigBox animate={animate}>
          {showCopyPopup && (
            <Popup><PopupText>Link copied!</PopupText></Popup>
          )}
          {showMaxFavoritePopup && (
            <Popup><PopupText>You already have maximum favorites! Remove some first.</PopupText></Popup>
          )}
          {showErrorPopup && (
            <ErrorPopup><PopupText>Generation failed. Our servers are experiencing high demand--please try again.</PopupText></ErrorPopup>
          )}
            <Container>
                <LeftContainer>
                  {((caseImageStatus === 'loaded' && printStatus === 'loaded' && printWidth && printHeight) || (!userUpload)) && (caption !== 'skeleton') ? (
                    <div style={canvasStyling}>
                        <Stage width={canvasWidth*(1748/2480)} height={canvasWidth}>
                            <Layer>
                                <Rect
                                  x={0}
                                  y={0}
                                  width={canvasWidth*(1748/2480)}
                                  height={canvasWidth}
                                  fill={fill}
                                />
                                <Image image={print} x={printX} y={printY} width={printWidth} height={printHeight} draggable={isTransformerVisible} onDragMove={moveThePrint} ref={printRef} dragBoundFunc={printDragBoundFunc}/>
                                <Image image={caseImage} x={0} y={0} width={canvasWidth*(1748/2480)} height={canvasWidth} listening={false}/>
                                {isTransformerVisible && (
                                  <Transformer
                                    ref={transformerRef}
                                    rotationSnaps={[0, 90, 180, 270]}
                                    rotationSnapTolerance={45}
                                    boundBoxFunc={(oldBox, newBox) => {
                                      return newBox;
                                    }}
                                    onTransformEnd={onTransformEnd}
                                  />
                                )}
                            </Layer>
                        </Stage>
                    </div>
                  ) : (
                    <ContentLoader 
                        speed={2}
                        width={window.innerWidth > 800 ? Math.min(document.documentElement.clientWidth/2, window.innerHeight-80) : '100%'}
                        height={window.innerWidth > 800 ? Math.min(document.documentElement.clientWidth/2, window.innerHeight-80) : Math.min(document.documentElement.clientWidth, 400)}
                        viewBox="0 0 1000 1000"
                        backgroundColor="#f3f3f3"
                        foregroundColor="#ecebeb"
                        style={skeletonStyling}
                    >
                        <path
                            d="M 0 0 L 0 1000 L 1000 1000 L 1000 0 L 0 0 z M 361.53711 133 L 638.46289 133 C 686.22859 133 725 171.77141 725 219.53711 L 725 807.99414 C 725 855.75985 686.22859 894.53125 638.46289 894.53125 L 361.53711 894.53125 C 313.77141 894.53125 275 855.75985 275 807.99414 L 275 219.53711 C 275 171.77141 313.77141 133 361.53711 133 z M 344.22852 236.85352 L 344.22852 721.46875 L 655.77148 721.46875 L 655.77148 236.85352 L 344.22852 236.85352 z M 500.00586 756.08398 C 471.27415 756.08398 448.08398 779.27415 448.08398 808.00586 C 448.08398 836.73756 471.27415 859.92773 500.00586 859.92773 C 528.73756 859.92773 551.92773 836.73756 551.92773 808.00586 C 551.92773 779.27415 528.73756 756.08398 500.00586 756.08398 z "
                        />
                    </ContentLoader>
                  )}
                  <ControlsContainer>
                    <ResetButton onClick={toggleTransformer}>{isTransformerVisible ? 'Hide Border' : 'Show Border'}</ResetButton>
                    <ResetButton onClick={reset}>Reset</ResetButton>
                  </ControlsContainer>
                  <BarForTwoCircleButtons render={false}>
                    <TwoCircleButtons>
                      <FavButton valid={savedImages.includes('')}>
                        {savedImages.includes('') ? (
                          <FavoriteIcon/>
                        ) : (
                          <FavoriteBorderIcon/>
                        )}
                      </FavButton>
                      <ShareButton><IosShareIcon/></ShareButton>
                    </TwoCircleButtons>
                  </BarForTwoCircleButtons>
                </LeftContainer>
                <RightContainer>
                  <Wrapper>
                    <TimelineContainer>
                      <TimelineEvent>
                        <TimelineLine isValid={caption && userUpload}/>
                        <TimelineIcon isValid={userUpload}>
                          {userUpload ? (
                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#fff" class="bi bi-check-lg" viewBox="0 0 16 16">
                              <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z"/>
                            </svg>
                          ) : (
                            <TimelineIconNumber>1</TimelineIconNumber>
                          )}
                        </TimelineIcon>
                        <TimelineContent>
                          <LabelNoMarginBottom>Image Upload</LabelNoMarginBottom>
                          <HiddenInput
                              type="file"
                              ref={fileInputRef}
                              onChange={handleUploadAndNavigateToCustomize}
                              accept="image/*"
                          />
                          <HelpfulTextNoMarginBottom>
                            {userUpload ? (
                              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="#5e5e5e" class="bi bi-check-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                                <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05"/>
                              </svg>
                            ) : (
                              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="#5e5e5e" class="bi bi-x-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
                              </svg>
                            )}
                            {userUpload ? (userUpload[1].name.length > 25 ? `${userUpload[1].name.substring(0, 20)}...${userUpload[1].name.substring(userUpload[1].name.lastIndexOf('.') - 3)}` : userUpload[1].name) : 'No image selected.'}
                          </HelpfulTextNoMarginBottom>
                          <FileChooserButton onClick={() => clickTheHiddenFileInput()}>Upload a new image</FileChooserButton>
                        </TimelineContent>
                      </TimelineEvent>
                      <TimelineEvent>
                        <TimelineLine isValid={caption}/>
                        <TimelineIcon isValid={caption}>
                          {caption ? (
                              <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#fff" class="bi bi-check-lg" viewBox="0 0 16 16">
                                <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z"/>
                              </svg>
                            ) : (
                              <TimelineIconNumber>2</TimelineIconNumber>
                          )}
                        </TimelineIcon>
                        <TimelineContent>
                          <LabelNoMarginBottom>Image Description</LabelNoMarginBottom>
                          <HelpfulText>Describe the contents of your image. This helps guide the AI stylizer. Add any details you don't want the stylizer to overlook.</HelpfulText>
                          <TextArea placeholder="Description" value={caption} onChange={handleCaptionChange}></TextArea>
                        </TimelineContent>
                      </TimelineEvent>
                      <TimelineEvent>
                        <TimelineLine isValid={fill}/>
                        <TimelineIcon isValid={true}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#fff" class="bi bi-check-lg" viewBox="0 0 16 16">
                            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z"/>
                          </svg>
                        </TimelineIcon>
                        <TimelineContent>
                          <Label>Phone Type</Label>
                          <SelectContainer>
                            <NewSelect1
                              options={makeOptions}
                              styles={selectStyles}
                              placeholder={make}
                              value={null}
                              isSearchable={false}
                              components={{
                                IndicatorSeparator: () => null
                              }}
                              onChange={handleMakeChange}
                            />
                            <NewSelect2
                              options={sizeOptions}
                              styles={selectStyles}
                              placeholder={selectedSize}
                              value={null}
                              isSearchable={false}
                              components={{
                                IndicatorSeparator: () => null
                              }}
                              onChange={handleSizeChange}
                            />
                          </SelectContainer>
                        </TimelineContent>
                      </TimelineEvent>
                      <TimelineEvent>
                        <TimelineLine />
                        <TimelineIcon isValid={fill}>
                          {fill ? (
                              <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#fff" class="bi bi-check-lg" viewBox="0 0 16 16">
                                <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z"/>
                              </svg>
                            ) : (
                              <TimelineIconNumber>4</TimelineIconNumber>
                          )}
                        </TimelineIcon>
                        <TimelineContent>
                          <Label>Fill</Label>
                          <SwatchContainer>
                            <ColorSwatch color='#000000' isSelected={fill === '#000000'} onClick={() => handleFillChange('#000000')}>
                              {fill === '#000000' && (
                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#fff" class="bi bi-check-lg" viewBox="0 0 16 16">
                                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z"/>
                                </svg>
                              )}
                            </ColorSwatch>
                            <ColorSwatch color='#FFFFFF' isSelected={fill === '#FFFFFF'} onClick={() => handleFillChange('#FFFFFF')}>
                              {fill === '#FFFFFF' && (
                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#000" class="bi bi-check-lg" viewBox="0 0 16 16">
                                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z"/>
                                </svg>
                              )}
                            </ColorSwatch>
                            <ColorSwatch color='#FF595E' isSelected={fill === '#FF595E'} onClick={() => handleFillChange('#FF595E')}>
                              {fill === '#FF595E' && (
                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#000" class="bi bi-check-lg" viewBox="0 0 16 16">
                                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z"/>
                                </svg>
                              )}
                            </ColorSwatch>
                            <ColorSwatch color='#FFCA3A' isSelected={fill === '#FFCA3A'} onClick={() => handleFillChange('#FFCA3A')}>
                              {fill === '#FFCA3A' && (
                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#000" class="bi bi-check-lg" viewBox="0 0 16 16">
                                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z"/>
                                </svg>
                              )}
                            </ColorSwatch>
                            <ColorSwatch color='#8AC926' isSelected={fill === '#8AC926'} onClick={() => handleFillChange('#8AC926')}>
                              {fill === '#8AC926' && (
                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#000" class="bi bi-check-lg" viewBox="0 0 16 16">
                                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z"/>
                                </svg>
                              )}
                            </ColorSwatch>
                            <ColorSwatch color='#1982C4' isSelected={fill === '#1982C4'} onClick={() => handleFillChange('#1982C4')}>
                              {fill === '#1982C4' && (
                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#000" class="bi bi-check-lg" viewBox="0 0 16 16">
                                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z"/>
                                </svg>
                              )}
                            </ColorSwatch>
                          </SwatchContainer>
                        </TimelineContent>
                      </TimelineEvent>
                      <TimelineEvent>
                        <TimelineIcon>
                          <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#eee" class="bi bi-stars" viewBox="0 0 16 16">
                            <path d="M7.657 6.247c.11-.33.576-.33.686 0l.645 1.937a2.89 2.89 0 0 0 1.829 1.828l1.936.645c.33.11.33.576 0 .686l-1.937.645a2.89 2.89 0 0 0-1.828 1.829l-.645 1.936a.361.361 0 0 1-.686 0l-.645-1.937a2.89 2.89 0 0 0-1.828-1.828l-1.937-.645a.361.361 0 0 1 0-.686l1.937-.645a2.89 2.89 0 0 0 1.828-1.828l.645-1.937zM3.794 1.148a.217.217 0 0 1 .412 0l.387 1.162c.173.518.579.924 1.097 1.097l1.162.387a.217.217 0 0 1 0 .412l-1.162.387A1.734 1.734 0 0 0 4.593 5.69l-.387 1.162a.217.217 0 0 1-.412 0L3.407 5.69A1.734 1.734 0 0 0 2.31 4.593l-1.162-.387a.217.217 0 0 1 0-.412l1.162-.387A1.734 1.734 0 0 0 3.407 2.31l.387-1.162zM10.863.099a.145.145 0 0 1 .274 0l.258.774c.115.346.386.617.732.732l.774.258a.145.145 0 0 1 0 .274l-.774.258a1.156 1.156 0 0 0-.732.732l-.258.774a.145.145 0 0 1-.274 0l-.258-.774a1.156 1.156 0 0 0-.732-.732L9.1 2.137a.145.145 0 0 1 0-.274l.774-.258c.346-.115.617-.386.732-.732L10.863.1z"/>
                          </svg>
                        </TimelineIcon>
                        <TimelineContent>
                          <Label>Style</Label>
                        </TimelineContent>
                      </TimelineEvent>
                    </TimelineContainer>
                    <ProceedButton disabled={!print} valid={print}>Continue<IconContainer></IconContainer></ProceedButton>
                  </Wrapper>
                </RightContainer>
            </Container>
        </BigBigBox>
    );
};

export default CustomizeFromUpload;
